import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProductInfo.css';

const ProductInfo = () => {
    const [products, setProducts] = useState([]);
    const [activeProduct, setActiveProduct] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get('https://api.shreejiinternationals.in/products');
                const sortedProducts = Array.isArray(response.data)
                    ? response.data.sort((a, b) => a.TechnicalName.localeCompare(b.TechnicalName))
                    : [];
                setProducts(sortedProducts);
            } catch (error) {
                setError('Error fetching products. Please try again later.');
                console.error('Error fetching products:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const toggleProductInfo = (_id) => {
        setActiveProduct(activeProduct === _id ? null : _id);
    };

    const renderProducts = () => {
        if (!Array.isArray(products) || products.length === 0) {
            return <p>No products available</p>;
        }
        return products.map((product) => (
            <div className="product-container" key={product._id}>
                <div className={`product-item ${activeProduct === product._id ? 'expanded' : ''}`}>
                    <div className="product-title" onClick={() => toggleProductInfo(product._id)}>
                        {product.TechnicalName}
                    </div>
                    {activeProduct === product._id && (
                        <div className="product-info">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Technical Name</th>
                                        <td>{product.TechnicalName || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <th>CAS No.</th>
                                        <td>{product.CASno || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <th>Chemical Structure</th>
                                        <td>
                                            <img src={product.ChemicalStructure || 'default-image-url'} alt="Chemical Structure" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Packing</th>
                                        <td>{product.Packing || 'N/A'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        ));
    };

    return (
        <div className="product-info-container">
            {error && <div className="error-message">{error}</div>}
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '50px' }}>
                    <div className="loader"></div> {/* Ensure you have CSS for the loader */}
                </div>
            ) : (
                <>
                    <header className="product-header">
                        <div className="cover-image">
                            <div className="cover-text">
                                <h1>Our Products</h1>
                                <p>Quality Products for Quality Produce</p>
                                <div className="brochure-download">
                                    <a href="/SIO.html" target='_blank'>
                                        <button>SIO-Brochure</button>
                                    </a><br /><br />
                                    <a href="/SIA.html" target='_blank'>
                                        <button>SIA-Brochure</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="product-list">{renderProducts()}</div>
                </>
            )}
        </div>
    );
};

export default ProductInfo;
